/**
 * Variable name for Data Pre-fetcher result in the window object
 */ export const DataPreFetcherResultVarName = "dataPreFetcherResult";
/**
 * Variable name for Data Pre-fetcher completed status in the window object
 */ export const DataPreFetchCompletedVarName = "dataPreFetchCompleted";
export var DataPreFetcherEventNames;
(function(DataPreFetcherEventNames) {
    /**
     * Event name for Data Pre-fetcher completion
     */ DataPreFetcherEventNames["FetchCompleted"] = "DataPreFetchCompleted";
    /**
     * Event name for Data Pre-fetcher Mru data fetched
     */ DataPreFetcherEventNames["FetchedMruData"] = "DataPreFetcherMruDataFetched";
    /**
     * Event name for Data Pre-fetcher MyDay tasks fetched
     */ DataPreFetcherEventNames["FetchedMyDayTasks"] = "DataPreFetcherMyDayTasksFetched";
    /**
     * Event name for Data Pre-fetcher ATM tasks fetched
     */ DataPreFetcherEventNames["FetchedATMTasks"] = "DataPreFetcherATMTasksFetched";
    /**
     * Event name for Data Pre-fetcher Plan fetched
     */ DataPreFetcherEventNames["FetchedPlan"] = "DataPreFetcherPlanFetched";
    /**
     * Event name for Data Pre-fetcher TeamsTasksTabs fetched
     */ DataPreFetcherEventNames["FetchedTTTabs"] = "DataPreFetcherTTTabsFetched";
    /**
     * Event name for Data Pre-fetcher Buckets fetched
     */ DataPreFetcherEventNames["FetchedBuckets"] = "DataPreFetcherBucketsFetched";
    /**
     * Event name for Data Pre-fetcher BasicPlanTasks fetched
     */ DataPreFetcherEventNames["FetchedBasicPlanTasks"] = "DataPreFetcherBasicPlanTasksFetched";
})(DataPreFetcherEventNames || (DataPreFetcherEventNames = {}));
export var PreFetchedDataKeys;
(function(PreFetchedDataKeys) {
    /**
     * Mru data key
     */ PreFetchedDataKeys["Mru"] = "mru";
    /**
     * MyDay tasks data key
     */ PreFetchedDataKeys["MyDayTasks"] = "myDayTasks";
    /**
     * ATM tasks data key
     */ PreFetchedDataKeys["ATMTasks"] = "atmTasks";
    /**
     * Plan data key
     */ PreFetchedDataKeys["Plan"] = "plan";
    /**
     * TeamsTasksTabs data key
     */ PreFetchedDataKeys["TTTabs"] = "ttTabs";
    /**
     * Plan buckets data key
     */ PreFetchedDataKeys["Buckets"] = "buckets";
    /**
     * Basic plan tasks data key
     */ PreFetchedDataKeys["BasicPlanTasks"] = "basicPlanTasks";
})(PreFetchedDataKeys || (PreFetchedDataKeys = {}));
