import { ViewName } from "./ViewConstants";
export var SubRouteType;
(function(SubRouteType) {
    /** Basic plan view */ SubRouteType["BasicPlan"] = "plan";
    /** Portfolio view */ SubRouteType["Portfolio"] = "portfolio";
    /** Premium plan (Project) view */ SubRouteType["PremiumPlan"] = "premiumplan";
    /** Legacy list (Todo) view */ SubRouteType["TodoList"] = "list";
    /** My day view */ SubRouteType["MyDay"] = "myday";
    /** My tasks view */ SubRouteType["MyTasks"] = "mytasks";
    /** Collections hub */ SubRouteType["MyPlans"] = "myplans";
    /** My portfolios view */ SubRouteType["MyPortfolios"] = "myportfolios";
    /** Task publishing */ SubRouteType["Publishing"] = "publishing";
    /** Message task */ SubRouteType["MessageTasks"] = "messagetasks";
    /** Create / edit teams tab */ SubRouteType["ConfigTeamsTab"] = "configteamstab";
    /** Remove teams tab */ SubRouteType["RemoveTeamsTab"] = "removeteamstab";
    /** New tab landing page view */ SubRouteType["NewTabPage"] = "newtabpage";
})(SubRouteType || (SubRouteType = {}));
export var SubRouteKeys;
(function(SubRouteKeys) {
    /** SubRoute View - Value after is ViewType*/ SubRouteKeys["View"] = "view";
    /** SubRoute Task - Value after is TaskId*/ SubRouteKeys["Task"] = "task";
    /** SubRoute Org - Value after is OrgId */ SubRouteKeys["Org"] = "org";
    /** SubRoute Dialog - Value after is ModalType */ SubRouteKeys["Dialog"] = "dialog";
    /** SubRoute Template type - Value after is template type */ SubRouteKeys["TemplateType"] = "templatetype";
    /** SubRoute Plan type - Value after is plan type */ SubRouteKeys["PlanType"] = "plantype";
})(SubRouteKeys || (SubRouteKeys = {}));
export var QueryParameterKeys;
(function(QueryParameterKeys) {
    /** Modal */ QueryParameterKeys["Modal"] = "modal";
    /** Template type for create plan modal */ QueryParameterKeys["TemplateType"] = "templatetype";
})(QueryParameterKeys || (QueryParameterKeys = {}));
export var ViewType;
(function(ViewType) {
    ViewType["Grid"] = "grid";
    ViewType["Board"] = "board";
    ViewType["Charts"] = "charts";
    ViewType["Schedule"] = "schedule";
    ViewType["Timeline"] = "timeline";
    ViewType["People"] = "people";
    ViewType["Goals"] = "goals";
    ViewType["Assignments"] = "assignments";
    ViewType["CopilotHome"] = "copilothome";
    ViewType["ProjectHomePage"] = "projecthomepage";
    ViewType["Report"] = "report";
})(ViewType || (ViewType = {}));
export var PortfolioViewType;
(function(PortfolioViewType) {
    PortfolioViewType["Roadmap"] = "roadmap";
    PortfolioViewType["Plans"] = "plans";
})(PortfolioViewType || (PortfolioViewType = {}));
/**
 * The prefix for legacy Task List subRoutes
 */ export const legacyTaskListTypePrefix = [
    "personalApp",
    "alltasklists",
    "taskListType"
];
/**
 * Mapping of primary views that render on each subRouteType
 */ export const SubRouteTypeToViewNameMap = {
    ["plan"]: ViewName.BasicPlanPage,
    ["premiumplan"]: ViewName.PremiumPlanPage,
    ["list"]: ViewName.BasicPlanPage,
    ["myday"]: ViewName.MyDayPage,
    ["mytasks"]: ViewName.MyTasksPage,
    ["myplans"]: ViewName.MyPlansPage,
    ["myportfolios"]: ViewName.MyPortfoliosPage,
    ["publishing"]: ViewName.PublishingPage,
    ["messagetasks"]: ViewName.CreateTaskPage,
    ["configteamstab"]: ViewName.ConfigTeamsTabPage,
    ["removeteamstab"]: ViewName.RemoveTeamsTabPage,
    ["newtabpage"]: ViewName.NewTabPage,
    ["portfolio"]: ViewName.PortfolioPage
};
