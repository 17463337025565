// Constants
import { ClientFlavor, persistentStorageSchemaVersion } from "@ms/uno-constants/lib/local/AppConstants";
import { legacyTaskListTypePrefix, SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { SettingsConstants } from "@ms/uno-constants/lib/local/SettingsConstants";
import { DataPreFetcherEventNames, DataPreFetchCompletedVarName } from "@ms/uno-constants/lib/local/DataPreFetcherConstants";
import { HtmlSettingsReader } from "@ms/uno-utilities/lib/local/HtmlSettingsReader";
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import { PreFetcherServices } from "./PreFetcherServices";
import { getInitialRoute } from "../../utilities/AppBootstrapRouteUtils";
import { extractBasicPlanRoutingDetailsFromSubRoute } from "@ms/uno-routing/lib/local/utilities/RouteUtilities";
/**
 * Data pre-fetcher that is used to prefetch data before the main application is loaded
 */
export class DataPreFetcher {
    /**
     * Constructor for the DataPreFetcher
     */
    constructor() {
        try {
            this.initializeSettings();
            this.preFetcherServices = new PreFetcherServices(this.config, this.tenantRegion, this.getAccessToken.bind(this), this.sendPreFetchEvent.bind(this));
        }
        catch (e) {
            this.mark("InitializationFailed");
        }
    }
    /**
     * Start pre-fetching the critical data
     */
    async init() {
        // If data pre-fetch is disabled, return
        if (!this.config["EnableDataPreFetcher"]) {
            return;
        }
        this.mark("PreFetchStarted");
        await this.setupHostDependencies();
        await this.preFetchData();
        this.mark("PreFetchCompleted");
    }
    /**
     * Initialize settings
     */
    initializeSettings() {
        const reader = new HtmlSettingsReader("planner");
        // Get config
        const config = reader.getSetting(SettingsConstants.Config);
        if (!config) {
            throw new Error("Config not available");
        }
        this.config = config;
        // Get client flavor from session metadata
        const sessionMetaData = reader.getSetting(SettingsConstants.SessionMetaData);
        if (!sessionMetaData?.appMetadata?.clientFlavor || !sessionMetaData?.tenantRegion) {
            throw new Error("SessionMetaData is not valid");
        }
        this.clientFlavor = sessionMetaData.appMetadata.clientFlavor;
        this.tenantRegion = sessionMetaData.tenantRegion;
    }
    /**
     * Mark different checkpoints in data pre-fetcher using performance api
     * @param checkpoint Checkpoint to mark
     * @param extraData Extra data to mark
     */
    mark(checkpoint, extraData) {
        const markName = `DataPreFetcher-checkpoint:${checkpoint}`;
        const extraDataEntries = extraData ? Object.entries(extraData).map(([key, value]) => `${key}:${value}`) : [];
        const markNameWithExtraData = [markName, ...extraDataEntries].join("-");
        performance.mark(markNameWithExtraData);
    }
    /**
     * Get initial route of the app
     */
    async getInitialRoute() {
        let initialRoute = getInitialRoute(this.clientFlavor);
        const persistedRoute = await this.getPersistedRoute();
        initialRoute = persistedRoute || initialRoute;
        const initialRouteFromDeepLink = await this.getInitialDeeplinkRoute();
        initialRoute = initialRouteFromDeepLink || initialRoute;
        return initialRoute;
    }
    /**
     * Get persisted route from session storage
     */
    async getPersistedRoute() {
        // If route persistence flight is disabled, return null
        if (!this.config["routePersistenceEnabled"]) {
            return null;
        }
        let route = null;
        const userId = await this.getUserId();
        const key = `${userId}-uno-${persistentStorageSchemaVersion}-rstore-croute`;
        const serializedRoute = window.localStorage.getItem(key);
        if (!serializedRoute) {
            return null;
        }
        try {
            route = JSON.parse(serializedRoute);
        }
        catch (e) {
            this.mark("PersistedRouteParsingFailed");
        }
        if (!route) {
            return null;
        }
        // Check if what we got is the right shape
        if (!route.subRouteType || stringToEnum(route.subRouteType, SubRouteType) == null) {
            this.mark("InvalidSubRouteTypeInPersistedRoute");
            return null;
        }
        // Check if subRoute format changed, and if so, we need to ensure
        // that we don't use persisted route if it's in legacy TT format
        const subRouteTypesChanged = [SubRouteType.MyDay, SubRouteType.MyTasks, SubRouteType.TodoList];
        if (subRouteTypesChanged.includes(route.subRouteType)) {
            const isEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
            // All TT subroutes start with ["personalApp", "alltasklists", "taskListType"]
            const isTT = route.subRoute.length > 2 && isEqual(route.subRoute.slice(0, 3), legacyTaskListTypePrefix);
            if (isTT) {
                // Stored route was TT, but we're on native. Return null to ignore this case
                return null;
            }
        }
        return route;
    }
    /**
     * Trigger data pre-fetch based on the current view
     */
    async preFetchData() {
        // Promises to be awaited before sending pre-fetch completion event
        const fetcherPromises = [this.preFetchLeftNavDataIfNeeded()];
        const currentRoute = await this.getInitialRoute();
        const { subRouteType, subRoute } = currentRoute;
        // Pre-fetch data based on the current view
        switch (subRouteType) {
            case SubRouteType.MyDay:
                fetcherPromises.push(this.preFetchMyDayData());
                break;
            case SubRouteType.MyTasks:
                fetcherPromises.push(this.preFetchMyTasks());
                break;
            case SubRouteType.MyPlans:
                fetcherPromises.push(this.preFetchMyPlans());
                break;
            case SubRouteType.BasicPlan:
                const routingDetails = extractBasicPlanRoutingDetailsFromSubRoute(subRoute);
                if (!routingDetails) {
                    this.mark("InvalidBasicPlanSubRoute");
                }
                else {
                    fetcherPromises.push(this.preFetchBasicPlan(routingDetails.planId));
                }
                break;
            default:
                this.mark("PreFetchUnsupported", { subrouteType: subRouteType });
                break;
        }
        await Promise.all(fetcherPromises);
        const globalWindowElement = window;
        globalWindowElement[DataPreFetchCompletedVarName] = true;
        this.sendPreFetchEvent(DataPreFetcherEventNames.FetchCompleted);
    }
    /**
     * Pre-fetch left nav data if needed
     */
    async preFetchLeftNavDataIfNeeded() {
        if (this.clientFlavor !== ClientFlavor.App) {
            return;
        }
        await this.preFetcherServices.fetchMruData();
    }
    /**
     * Pre-fetch MyDay data
     */
    async preFetchMyDayData() {
        await this.preFetcherServices.fetchPlannerMyDayTasksData();
    }
    /**
     * Pre-fetch MyTasks data
     */
    async preFetchMyTasks() {
        await this.preFetcherServices.fetchPlannerAssignedToMeTasksData();
    }
    /**
     * Pre-fetch MyPlans data
     */
    async preFetchMyPlans() {
        await this.preFetcherServices.fetchTTTabsData();
    }
    /**
     * Pre-fetch basic plan data
     */
    async preFetchBasicPlan(planId) {
        if (!this.config["EnableNativeBasicPlanViews"]) {
            return;
        }
        await Promise.all([
            this.preFetcherServices.fetchPlan(planId),
            this.preFetcherServices.fetchBuckets(planId),
            this.preFetcherServices.fetchTasksForBasicPlan(planId),
        ]);
    }
    /**
     * Sends a pre-fetcher event
     * @param eventName The event name to send
     */
    sendPreFetchEvent(eventName) {
        const event = new Event(eventName);
        window.dispatchEvent(event);
    }
}
