// Constants
import { HttpContentType, HttpHeaders, HttpMethods } from "@ms/uno-constants/lib/local/HttpConstants";
import { TenantRegion } from "@ms/uno-constants/lib/local/AppConstants";
import { DataPreFetcherEventNames, PreFetchedDataKeys } from "@ms/uno-constants/lib/local/DataPreFetcherConstants";
import { fetchWithTimeout, generateAjaxClientError, generateLogStringFromAjaxClientError, } from "@ms/uno-services/lib/local/utilities/ServiceUtilities";
import { MaxMruCount, MruHeaders } from "@ms/uno-services/lib/local/services/mru/MruConstants";
import { GraphODataApiVersion } from "@ms/uno-services/lib/local/services/graph/constants/GraphServiceConstants";
/**
 * Pre-fetcher services
 */
export class PreFetcherServices {
    /**
     * Constructor for the PreFetcherServices
     * @param config The configuration
     * @param tenantRegion The tenant region
     * @param tokenProvider The token provider
     * @param sendPreFetchEvent The send pre-fetch event
     */
    constructor(config, tenantRegion, tokenProvider, sendPreFetchEvent) {
        this.config = config;
        this.tenantRegion = tenantRegion;
        this.tokenProvider = tokenProvider;
        this.sendPreFetchEvent = sendPreFetchEvent;
        /**
         * Key for the graph skip token in the response
         */
        this.graphSkipTokenKey = "@odata.nextLink";
    }
    /**
     * Fetches the MRU data
     */
    async fetchMruData() {
        let result;
        try {
            const mruServiceConfig = this.getPreFetcherServiceConfiguration("mru");
            const baseUrl = this.tenantRegion === TenantRegion.Row ? mruServiceConfig.hostname : mruServiceConfig.hostnameEu;
            if (!baseUrl) {
                throw generateAjaxClientError(null, "Base URL is required for MRU service");
            }
            const apiUrl = `${baseUrl}/ocs/v2/recent?show=${MaxMruCount}${"&apps=Project,Planner,ToDo,Portfolio"}${"&cap=DocumentUrl&sort=date"}`;
            const token = await this.tokenProvider(mruServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {
                method: HttpMethods.Get,
                headers: {
                    ...this.getMruHeaders(),
                    [HttpHeaders.ContentType]: HttpContentType.Json,
                },
            });
            const data = await response.clone().json();
            if (!data) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            const mruResources = [...(data.documents?.items || []), ...(data.places?.items || [])];
            result = {
                data: mruResources,
                errorMessage: undefined,
            };
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            result = {
                data: [],
                errorMessage: errorMessage,
            };
        }
        // Write the mru service result to window
        this.writeServiceResult(PreFetchedDataKeys.Mru, result, DataPreFetcherEventNames.FetchedMruData);
    }
    /**
     * Fetches the planner my day tasks data
     * Fetching all pages instead of just the first page because the next link is not stored.
     * Therefore, we can't dedupe only the first page call.
     * @param accumulatedTasks The accumulated tasks
     * @param nextLink The next link
     */
    async fetchPlannerMyDayTasksData(accumulatedTasks = [], nextLink) {
        try {
            const graphServiceConfig = this.getPreFetcherServiceConfiguration("graph");
            const baseUrl = graphServiceConfig.hostname;
            const apiUrl = nextLink ?? `${baseUrl}/${GraphODataApiVersion.Beta}/me/planner/mydaytasks?$expand=details`;
            const token = await this.tokenProvider(graphServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {});
            const data = await response.clone().json();
            if (!data) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            // Extract next link from the response
            const nextLinkInResponse = this.extractGraphNextLink(data[this.graphSkipTokenKey]);
            // Accumulate the tasks from the response
            const tasks = data.value;
            accumulatedTasks.push(...tasks);
            const result = {
                data: accumulatedTasks,
                errorMessage: undefined,
            };
            this.writeServiceResult(PreFetchedDataKeys.MyDayTasks, result, DataPreFetcherEventNames.FetchedMyDayTasks);
            // If there is a next link, fetch the next page of tasks otherwise return the accumulated tasks
            if (nextLinkInResponse) {
                await this.fetchPlannerMyDayTasksData(accumulatedTasks, nextLinkInResponse);
            }
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            const result = {
                data: [],
                errorMessage: errorMessage,
            };
            this.writeServiceResult(PreFetchedDataKeys.MyDayTasks, result, DataPreFetcherEventNames.FetchedMyDayTasks);
        }
    }
    /**
     * Fetches the planner assigned to me tasks data.
     * Fetching all pages instead of just the first page because the next link is not stored.
     * Therefore, we can't dedupe only the first page call.
     * @param accumulatedTasks The accumulated tasks
     * @param nextLink The next link
     */
    async fetchPlannerAssignedToMeTasksData(accumulatedTasks = [], nextLink) {
        try {
            const graphServiceConfig = this.getPreFetcherServiceConfiguration("graph");
            const baseUrl = graphServiceConfig.hostname;
            const apiUrl = `${baseUrl}${nextLink ?? `/${GraphODataApiVersion.Beta}/me/planner/tasks?$expand=details`}`;
            const token = await this.tokenProvider(graphServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {
                headers: {
                    Prefer: "include-unknown-enum-members",
                },
            });
            const tasksPage = await response.clone().json();
            if (!tasksPage) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            // Extract next link from the response
            const nextLinkInResponse = this.extractGraphNextLink(tasksPage[this.graphSkipTokenKey]);
            // Accumulate the tasks from the response
            const atmTasks = tasksPage.value;
            accumulatedTasks.push(...atmTasks);
            const result = {
                data: accumulatedTasks,
                errorMessage: undefined,
            };
            // Write the service result to window without wait for the plan fetch to complete
            this.writeServiceResult(PreFetchedDataKeys.ATMTasks, result, DataPreFetcherEventNames.FetchedATMTasks);
            // If there is a next link, fetch the next page of tasks otherwise return the accumulated tasks
            if (nextLinkInResponse) {
                await this.fetchPlannerAssignedToMeTasksData(accumulatedTasks, nextLinkInResponse);
            }
            const isLazyLoadingOfPlanDetailsEnabled = this.config["EnableLazyLoadingOfPlanDetails"] ?? false;
            if (!isLazyLoadingOfPlanDetailsEnabled) {
                const fetchPlanPromises = atmTasks.map((task) => this.fetchPlan(task.planId));
                await Promise.all(fetchPlanPromises);
            }
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            const result = {
                data: [],
                errorMessage: errorMessage,
            };
            // Write the service result to window
            this.writeServiceResult(PreFetchedDataKeys.ATMTasks, result, DataPreFetcherEventNames.FetchedATMTasks);
        }
    }
    /**
     * Fetches the teams tasks tabs data
     */
    async fetchTTTabsData() {
        let result;
        try {
            const retailServicesConfig = this.getPreFetcherServiceConfiguration("retailservices");
            const baseUrl = retailServicesConfig.hostname;
            const appFlavor = retailServicesConfig.appFlavor;
            if (!appFlavor) {
                throw new Error("Client flavor is required for TeamsTasksTabs service");
            }
            const apiUrl = `${baseUrl}api/rest/v2/teams/tabs/delta?env=${appFlavor}`;
            const token = await this.tokenProvider(retailServicesConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {});
            const data = await response.clone().json();
            if (!data) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            result = {
                data: data.items ?? [],
                errorMessage: undefined,
            };
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            result = {
                data: [],
                errorMessage: errorMessage,
            };
        }
        this.writeServiceResult(PreFetchedDataKeys.TTTabs, result, DataPreFetcherEventNames.FetchedTTTabs);
    }
    /**
     * Fetches the plan data
     * @param planId The plan id to fetch
     */
    async fetchPlan(planId) {
        let result;
        try {
            const graphServiceConfig = this.getPreFetcherServiceConfiguration("graph");
            const baseUrl = graphServiceConfig.hostname;
            const apiUrl = `${baseUrl}/${GraphODataApiVersion.Beta}/planner/plans/${planId}?$expand=details`;
            const token = await this.tokenProvider(graphServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {
                headers: {
                    Prefer: "include-unknown-enum-members",
                },
            });
            const data = await response.clone().json();
            if (!data) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            result = {
                data: [data],
                errorMessage: undefined,
            };
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            result = {
                data: [],
                errorMessage: errorMessage,
            };
        }
        // Write the service result to window
        this.writeServiceResult(PreFetchedDataKeys.Plan, result, DataPreFetcherEventNames.FetchedPlan);
    }
    /**
     * Fetches all buckets for a plan
     * @param planId The plan id to fetch buckets for
     */
    async fetchBuckets(planId) {
        let result;
        try {
            const graphServiceConfig = this.getPreFetcherServiceConfiguration("graph");
            const baseUrl = graphServiceConfig.hostname;
            const apiUrl = `${baseUrl}/${GraphODataApiVersion.Beta}/planner/plans/${planId}/buckets`;
            const token = await this.tokenProvider(graphServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {});
            const data = await response.clone().json();
            if (!data) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            const bucketResources = data.value;
            result = {
                planId: planId,
                data: bucketResources,
                errorMessage: undefined,
            };
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            result = {
                planId: planId,
                data: [],
                errorMessage: errorMessage,
            };
        }
        // Write the service result to window
        this.writeServiceResult(PreFetchedDataKeys.Buckets, result, DataPreFetcherEventNames.FetchedBuckets);
    }
    /**
     * Fetches all tasks for a basic plan
     * Fetching all pages instead of just the first page because the next link is not stored.
     * Therefore, we can't dedupe only the first page call, fetching tasks will short circuit via makeRequestToStore.
     * @param planId The plan id to fetch tasks for
     * @param accumulatedTasks The accumulated tasks
     * @param nextLink The next link
     */
    async fetchTasksForBasicPlan(planId, accumulatedTasks = [], nextLink) {
        try {
            const graphServiceConfig = this.getPreFetcherServiceConfiguration("graph");
            const baseUrl = graphServiceConfig.hostname;
            const apiUrl = `${baseUrl}${nextLink ??
                `/${GraphODataApiVersion.Beta}/planner/plans/${planId}/tasks?$expand=details,assignedToTaskBoardFormat,bucketTaskBoardFormat,progressTaskBoardFormat`}`;
            const token = await this.tokenProvider(graphServiceConfig.authResourceURI);
            const response = await this.executeRequest(apiUrl, token, {
                headers: {
                    Prefer: "include-unknown-enum-members",
                },
            });
            const tasksPage = await response.clone().json();
            if (!tasksPage) {
                throw generateAjaxClientError(null, new Error("No data returned"));
            }
            // Extract next link from the response
            const nextLinkInResponse = this.extractGraphNextLink(tasksPage[this.graphSkipTokenKey]);
            // Accumulate the tasks from the response
            const tasks = tasksPage.value;
            accumulatedTasks.push(...tasks);
            const result = {
                planId: planId,
                data: accumulatedTasks,
                errorMessage: undefined,
            };
            this.writeServiceResult(PreFetchedDataKeys.BasicPlanTasks, result, DataPreFetcherEventNames.FetchedBasicPlanTasks);
            // If there is a next link, fetch the next page of tasks otherwise return the accumulated tasks
            if (nextLinkInResponse) {
                await this.fetchTasksForBasicPlan(planId, accumulatedTasks, nextLinkInResponse);
            }
        }
        catch (error) {
            const failedResult = error;
            const errorMessage = generateLogStringFromAjaxClientError(failedResult);
            const result = {
                planId: planId,
                data: [],
                errorMessage: errorMessage,
            };
            this.writeServiceResult(PreFetchedDataKeys.BasicPlanTasks, result, DataPreFetcherEventNames.FetchedBasicPlanTasks);
        }
    }
    /**
     * Get the graph token from the retail service
     */
    async getGraphTokenFromRetailService() {
        const retailServiceConfig = this.getPreFetcherServiceConfiguration("auth");
        const baseUrl = retailServiceConfig.hostname;
        const apiUrl = `${baseUrl}/api/rest/v1/tokenprovider/graph`;
        const token = await this.tokenProvider(retailServiceConfig.authResourceURI);
        const response = await this.executeRequest(apiUrl, token, {
            method: HttpMethods.Get,
        });
        const data = await response.clone().json();
        return data.accessToken;
    }
    /**
     * Sets common headers required by MRU service.
     */
    getMruHeaders() {
        const headers = {};
        const officeApplicationNumber = this.getPreFetcherServiceConfiguration("mru").officeApplicationNumber;
        if (!officeApplicationNumber) {
            throw new Error("Office application number is required for MRU service");
        }
        // These are REQUIRED properties for MRU and it will stop working without these
        headers[MruHeaders.OfficePlatform] = "Web";
        headers[MruHeaders.OfficeApplication] = officeApplicationNumber;
        headers[MruHeaders.OfficeVersion] = "16.0.0.0";
        return headers;
    }
    /**
     * Get the pre-fetcher service configuration
     * @param service The service to get the configuration
     */
    getPreFetcherServiceConfiguration(service) {
        const serviceConfig = this.config["serviceConfigurations"]?.[service];
        if (!serviceConfig?.hostname || !serviceConfig?.authResourceURI) {
            throw new Error(`Service configuration for ${service} not found`);
        }
        const preFetcherServiceConfig = {
            hostname: serviceConfig.hostname,
            authResourceURI: serviceConfig.authResourceURI,
            hostnameEu: serviceConfig.hostnameEu ?? undefined,
            officeApplicationNumber: serviceConfig.officeApplicationNumber ?? undefined,
            appFlavor: serviceConfig.appFlavor ?? undefined,
        };
        return preFetcherServiceConfig;
    }
    /**
     * Execute an request
     * @param url Resource url for the request
     * @param token The token for the request
     * @param options Options to configure the request
     * @param timeout Timeout for the request
     */
    async executeRequest(url, token, options, timeout) {
        this.setAcceptRequestHeaders(options);
        this.setAuthorizationHeader(options, token);
        try {
            const response = await fetchWithTimeout(url, options, timeout ?? 15000);
            if (response.ok) {
                return response;
            }
            // Failure
            const failureResult = {
                response: response,
                error: null,
            };
            throw failureResult;
        }
        catch (e) {
            if (e.response != null) {
                // This was already thrown above as an IFailedRequestResult. No need to wrap again
                throw e;
            }
            // If no response returned
            const failureResult = {
                response: null,
                error: e.name === "AbortError" ? new Error("Timeout") : e,
            };
            throw failureResult;
        }
    }
    /**
     * Set the accept request headers
     * @param options Options to configure the request
     */
    setAcceptRequestHeaders(options) {
        const headers = new Headers(options.headers);
        if (headers.get(HttpHeaders.Accept) == null) {
            headers.set(HttpHeaders.Accept, "application/json, */*;q=0.01");
        }
        options.headers = headers;
    }
    /**
     * Updates the authorization header value
     * @param options The current headers
     * @param token The token we received
     */
    setAuthorizationHeader(options, token) {
        const headers = new Headers(options.headers);
        headers.set(HttpHeaders.Authorization, `Bearer ${token}`);
        // Add headers to options if there is no headers set yet
        options.headers = headers;
    }
    /**
     * Return nextLink without url part to ensure nextLink works property with graph Api components
     * @param nextLink The next link from a continuation token for a paginated response
     */
    extractGraphNextLink(nextLink) {
        const graphUrl = this.getPreFetcherServiceConfiguration("graph").hostname;
        if (nextLink?.indexOf(graphUrl) === 0) {
            nextLink = nextLink.substring(graphUrl.length);
        }
        return nextLink;
    }
    /**
     * Write the service result to window
     * @param key The key to write the result
     * @param result The pre-fetched service result
     * @param eventName The event name to send
     */
    writeServiceResult(key, result, eventName) {
        const dataPreFetcherResult = window.dataPreFetcherResult || {};
        if (!dataPreFetcherResult[key]) {
            dataPreFetcherResult[key] = result;
        }
        else {
            const existingResult = dataPreFetcherResult[key];
            dataPreFetcherResult[key] = {
                ...existingResult,
                ...result,
                data: [...existingResult.data, ...(result?.data || [])],
            };
        }
        window.dataPreFetcherResult = dataPreFetcherResult;
        this.sendPreFetchEvent(eventName);
    }
}
