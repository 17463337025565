/**
 * The ViewRenderState enum represents the render state of a particular view.
 * It applies to components that explicitly fetch data.
 */ export var ViewRenderState;
(function(ViewRenderState) {
    // All data is rendered within the component
    ViewRenderState[ViewRenderState["All"] = 2] = "All";
    // Visually critical data sufficient to make the component feel loaded is rendered
    ViewRenderState[ViewRenderState["Critical"] = 1] = "Critical";
    // No data is yet rendered and the view is empty
    ViewRenderState[ViewRenderState["None"] = 0] = "None";
})(ViewRenderState || (ViewRenderState = {}));
export var ViewDataFetchState;
(function(ViewDataFetchState) {
    // The data fetch is started
    ViewDataFetchState[ViewDataFetchState["Started"] = 0] = "Started";
    // The data fetch is completed
    ViewDataFetchState[ViewDataFetchState["Completed"] = 1] = "Completed";
})(ViewDataFetchState || (ViewDataFetchState = {}));
export var ViewName;
(function(ViewName) {
    ViewName["UnoLeftNavContainer"] = "UnoLeftNavContainer";
    ViewName["MyDayPage"] = "MyDayPage";
    ViewName["MyTasksPage"] = "MyTasksPage";
    ViewName["MyPlansPage"] = "MyPlansPage";
    ViewName["MyPortfoliosPage"] = "MyPortfoliosPage";
    ViewName["PortfolioPage"] = "PortfolioPage";
    ViewName["BasicPlanPage"] = "BasicPlanPage";
    ViewName["TaskEditorContainer"] = "TaskEditorContainer";
    ViewName["CreateTeamsTabPage"] = "CreateTeamsTabPage";
    ViewName["CreateTaskPage"] = "CreateTaskPage";
    ViewName["RemoveTeamsTabPage"] = "RemoveTeamsTabPage";
    ViewName["TeamsTabLandingExperienceContainer"] = "TeamsTabLandingExperienceContainer";
    ViewName["PremiumPlanPage"] = "PremiumPlanPage";
    ViewName["ConfigTeamsTabPage"] = "ConfigTeamsTabPage";
    ViewName["NewTabPage"] = "NewTabPage";
    ViewName["PublishingPage"] = "PublishingPage";
})(ViewName || (ViewName = {}));
