// For GDPR compliance it is important that we allow users to see all the MRU entries they have created.
// MRU service has a limit of 250 MRU entries per App (not currently enforced) and 1000 MRU entries per user (currently enforced).
// Query all 1000 entries to be GDPR compliant.
export const MaxMruCount = 1000;
export var MruHeaders;
(function(MruHeaders) {
    MruHeaders["OfficePlatform"] = "X-Office-Platform";
    MruHeaders["OfficeApplication"] = "X-Office-Application";
    MruHeaders["OfficeVersion"] = "X-Office-Version";
})(MruHeaders || (MruHeaders = {}));
