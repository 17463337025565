// Utilities
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
// Constants
import { HttpStatus } from "@ms/uno-constants/lib/local/HttpStatusConstants";
import { RetryAjaxHelperConfigConstants } from "@ms/uno-constants/lib/local/AppConstants";
import { HttpMethods } from "@ms/uno-constants/lib/local/HttpConstants";
import { Allotments, SelfServiceSession } from "../constants/ProjectSchedulingServiceConstants";
/**
 * Perform fetch while adding a timeout mechanic on top
 * @param url Resource url for the request
 * @param options Options to configure the request
 * @param globalTimeout Fallback global timeout value in MS
 */ export async function fetchWithTimeout(url, options, globalTimeout) {
    const controller = new AbortController();
    const id = setTimeout(()=>controller.abort(), options.timeout ?? globalTimeout);
    const response = await fetch(url, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);
    return response;
}
/**
 * Return an error object for a failure API call
 * @param response Response object
 * @param e an error that can be a string or an Error object
 */ export function generateAjaxClientError(response, e) {
    const failureResult = {
        response: response,
        error: typeof e === "string" ? new Error(e) : e ?? null
    };
    return failureResult;
}
/**
 * Generate a log string from a failed request result.
 * We don't want to log error message from the service as it may contain sensitive information.
 * @param failedRequestResult Failed request result
 */ export function generateLogStringFromAjaxClientError(failedRequestResult) {
    const { response, error, extraTelemetryData } = failedRequestResult;
    let extraTelemetryDataString = "";
    if (extraTelemetryData) {
        Object.entries(extraTelemetryData).forEach(([key, value])=>{
            extraTelemetryDataString += `[${key}=${value}]`;
        });
    }
    return `[StatusCode=${response?.status}][StatusText=${response?.statusText}][ErrorType=${error?.name}]${extraTelemetryDataString}`;
}
/**
 * Check the parameter for null values for the given keys. Generate the IFailedRequestResult for any null values.
 * @param parameters the request parameters to validate
 * @param notEmptyKeys (optional) list of keys to test with isEmpty
 */ export function validateAjaxClientRequestParameters(parameters, notEmptyKeys) {
    if (parameters == null) {
        return generateAjaxClientError(new Response("ArgumentNullException: parameters", {
            status: HttpStatus.BadRequest,
            statusText: "Bad Request"
        }), "ArgumentNullException: parameters");
    }
    if (notEmptyKeys) {
        for (const key of notEmptyKeys){
            if (isEmpty(parameters[key])) {
                const errorText = "ArgumentNullException: parameters." + key;
                return generateAjaxClientError(new Response(errorText, {
                    status: HttpStatus.BadRequest,
                    statusText: "Bad Request"
                }), errorText);
            }
        }
    }
    return null;
}
/**
 * Check if a failed request is retryable
 * @param statusCode Request status code
 */ export function isRetryableStatusCode(status) {
    if (status === HttpStatus.BadGateway || status === HttpStatus.ServiceUnavailable || status === HttpStatus.GatewayTimeout || status === 0) {
        // Gateway timeout
        return true;
    }
    return false;
}
/**
 * Return boolean indicating if given response error is a timeout error or not
 * @param response The current response
 */ export function isTimeoutErrorResponse(response) {
    return response.error?.message.toLowerCase() === "timeout"; // Ajax error message that indicates timeout
}
/**
 * Gets the default ajax retry config
 * @param overrides The overrides to apply to the default config
 */ export function getAjaxRetryConfig(overrides) {
    return merge({
        delayOnRetryInMiliseconds: RetryAjaxHelperConfigConstants.delayOnRetryInMiliseconds,
        maxRetryCount: RetryAjaxHelperConfigConstants.maxRetryCount,
        maxRetryTimeoutInMiliseconds: RetryAjaxHelperConfigConstants.maxRetryTimeoutInMiliseconds,
        retryCheck: retryCheckCallbackForAjaxClientRequest
    }, overrides);
}
/**
 * Callback to check on a failure response if a retry should be attempted or not, returning TRUE will retry the request otherwise retry loop will stop.
 * @param options The request options
 * @param failureResult The failure result of the request
 */ export async function retryCheckCallbackForAjaxClientRequest(options, failureResult) {
    return isGetRequest(options) && isRetryableResponse(failureResult);
}
/**
 * Returns boolean indicating if given response is retryable or not
 * @param response The current response
 */ export async function isRetryableResponse(response) {
    const statusCode = response?.response?.status ?? 0;
    return isRetryableStatusCode(statusCode) || // One of retryable status code
    isTimeoutErrorResponse(response);
}
/**
 * Check if the request method is GET
 * @param options Request option
 */ export function isGetRequest(options) {
    return options.method == null || options.method.toUpperCase() === HttpMethods.Get;
}
/**
 * Get the headers for a project api call, if current session is a self-service session, add force strong consistency header
 * @param headers headers of a project api call
 * @param isSelfService whether current session is a self-service session
 */ export function getProjectApiCallHeader(headers, isSelfService) {
    if (isSelfService) {
        headers[SelfServiceSession] = Allotments;
        return headers;
    } else {
        return headers;
    }
}
/**
 * Get the header value from the failed request result response if it exists
 * @param failedRequestResult Failed request result
 * @param headerName The name of the response header to get
 */ export function getHeaderFromFromAjaxClientError(failedRequestResult, headerName) {
    return failedRequestResult.response?.headers.get(headerName) ?? null;
}
/**
 * Type guard for IFailedRequestResult
 * @param obj The object to check
 * @returns True if the object is an IFailedRequestResult
 */ export function isFailedRequestResult(obj) {
    return typeof obj === "object" && obj !== null && "response" in obj && (obj.response === null || obj.response instanceof Response) && "error" in obj && (obj.error === null || obj.error instanceof Error) && (obj.retryCount == null || typeof obj.retryCount === "number") && (obj.extraTelemetryData == null || typeof obj.extraTelemetryData === "object");
}
